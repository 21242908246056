import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import SvgLove from './svg-love';
import { req } from '@/api';

type RefBoolean = Ref<boolean>;
type RefString = Ref<string>;
interface VideoData {
    readonly onlyid: string;
    readonly title: string;
    readonly backImg: string;
    playCount?: number;
    praiseCount: number;
    readonly isTop?: boolean;
}

export default defineComponent({
    components: { SvgLove },
    setup() {
        const isLoading: RefBoolean = ref(true);
        const isError: RefBoolean = ref(false);
        const errorMsg: RefString = ref('');
        const videoData: Ref<VideoData[]> = ref([
            {
                onlyid: 'uiasuyjhewq',
                title: '男女之间有纯友谊吗',
                backImg: '/static/testimg/photo/1.jpg',
                praiseCount: 12345,
                isTop: true,
            },
            {
                onlyid: 'fsdwe234',
                title: '淡淡的青春，纯纯的爱',
                backImg: '/static/testimg/photo/2.jpg',
                praiseCount: 12345,
            },
        ]);
        const formatNum = (num: number): string =>
            num >= 10000
                ? (Math.floor(num / 1000) / 10).toString() + 'w'
                : num.toString();
        const formatTitle = (title: string): string =>
            title.length > 50 ? title.substring(0, 47) + '...' : title;

        const reqData = () => {
            isLoading.value = true;
            isError.value = false;
            req('PHOTO_SHORTVIDEO_ALL')
                .then((data: any) => (videoData.value = data))
                .catch(error => {
                    isError.value = true;
                    errorMsg.value = '' + error;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        };
        onMounted(() => {
            reqData();
        });
        return {
            videoData,
            formatNum,
            formatTitle,
            isLoading,
            isError,
            errorMsg,
            reqData,
        };
    },
});
