import { defineComponent, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import ShortvideoList from '@/components/Article/ShortvideoList';

export default defineComponent({
    components: { ShortvideoList },
    setup() {
        return {};
    },
});
